import { fetchProductionPrognose } from "store/appSlice";
import {
  unverifyProductionPrognose,
  updateProductionForecastData,
  updateProductionPrognose,
  verifyProductionPrognose,
} from "api/resources/productionForecasts";
import {
  CellJsonPrognosePeriodProduction,
  ForecastType,
  ParkData,
  PeriodData,
  ProductionPrognoseValues,
  TableMatrixProduction,
  TotalProduction,
  TotalProductionSum,
} from "api/models/TableMatrix";
import {
  ProductionFieldUpdate,
  ProductionForecast,
  ProductionForecastWeights,
  PrognosePeriodProduction,
  UpdateProductionPrognosisDto,
} from "api/models/ProductionForecast";
import { ProductionPark } from "api/models/ProductionPark";
import { ProtectedPaths } from "routes";
import { ProductionParkType } from "api/models/enums/ProductionParkType";
import React from "react";

export const handleCellClick = (
  tableMatrix: TableMatrixProduction[],
  setTableMatrix: (matrix: TableMatrixProduction[]) => void,
  overallValues: any[],
  setOverallValues: (values: any) => void,
  selectedPriceArea: number,
  parkIndex: number,
  rowIndex: number,
  type: "forecaData" | "aiolosData" | "meteologicaData" | "forecast",
) => {
  const tableMatrixClone = [...tableMatrix];
  const park = tableMatrixClone[selectedPriceArea].parks[parkIndex];
  const reading = park.readings[rowIndex];
  const weights = tableMatrix[selectedPriceArea].forecastWeights;

  const updateCellSelection = (data: any) => {
    if (data) {
      return {
        ...data,
        selected: !data.selected,
      };
    }
    return data;
  };

  if (type === "forecast") {
    reading.totalProductionSum.forecast.selected =
      !reading.totalProductionSum.forecast.selected;
  } else if (reading.totalProduction[type]) {
    reading.totalProduction[type] = updateCellSelection(
      reading.totalProduction[type],
    );
  }

  calculateTotalProductionSum(reading, weights);

  const updatedOverallValues = [...overallValues];
  const overallRow = updatedOverallValues[selectedPriceArea].periods[rowIndex];

  if (type === "forecast") {
    if (!reading.totalProductionSum.forecast.selected) {
      overallRow.forecast -= reading.totalProductionSum.forecast.value;
    } else {
      overallRow.forecast += reading.totalProductionSum.forecast.value;
    }
  } else if (reading.totalProduction[type]) {
    if (!reading.totalProduction[type].selected) {
      overallRow[type] -= reading.totalProduction[type].value;
    } else {
      overallRow[type] += reading.totalProduction[type].value;
    }
  }

  setOverallValues(updatedOverallValues);

  calculateSummaryRow(
    tableMatrixClone,
    setTableMatrix,
    selectedPriceArea,
    rowIndex,
  );

  setTableMatrix(tableMatrixClone);
};

export const handleSaveForecast = (
  tableMatrix: TableMatrixProduction[],
  forecast: ProductionForecast,
  navigate: (path: string) => void,
  setSaving: (saving: boolean) => void,
  setSnackbar: (snackbar: { type: string; message: string }) => void,
) => {
  setSaving(true);

  const forecastData: UpdateProductionPrognosisDto = {
    cellsJson: tableMatrix.reduce(
      (acc, priceArea) => {
        priceArea.parks.forEach((park) => {
          if (!acc[park.productionPark.id]) {
            acc[park.productionPark.id] = [];
          }
          park.readings.forEach((reading, index) => {
            acc[park.productionPark.id][index] = {
              datetime: reading.totalProduction.dateTime,
              totalProduction: {
                forecaData: {
                  selected:
                    reading.totalProduction.forecaData?.selected || false,
                },
                aiolosData: {
                  selected:
                    reading.totalProduction.aiolosData?.selected || false,
                },
                meteologicaData: {
                  selected:
                    reading.totalProduction.meteologicaData?.selected || false,
                },
              },
              totalProductionSum: {
                forecast: {
                  selected:
                    reading.totalProductionSum.forecast?.selected || false,
                  value: reading.totalProductionSum.forecast?.value || 0,
                },
                adjustment: reading.totalProductionSum.adjustment,
              },
            } as ProductionFieldUpdate;
          });
        });
        return acc;
      },
      {} as Record<string, ProductionFieldUpdate[]>,
    ),
    filtersJson: [
      {
        forecastWeights: tableMatrix.reduce(
          (acc, priceArea) => {
            acc[priceArea.priceArea] = priceArea.forecastWeights;
            return acc;
          },
          {} as Record<string, ProductionForecastWeights>,
        ),
      },
    ],
    productionPrognoseValues: {
      totalValues: tableMatrix.reduce(
        (acc, priceArea) => {
          acc[priceArea.priceArea] = priceArea.periods.map((period, hour) => ({
            hour,
            adjustment: period.adjustment,
            value: period.total,
          }));
          return acc;
        },
        {} as Record<
          string,
          { hour: number; adjustment: number; value: number }[]
        >,
      ),
    },
  };

  updateProductionPrognose(forecast.id, forecastData)
    .then(() => {
      setSaving(false);
      navigate(`/${ProtectedPaths.Production}`);
    })
    .catch((error) => {
      setSaving(false);
      setSnackbar({
        type: "error",
        message: "Something went wrong when saving the forecast: " + error,
      });
    });
};

export const handleConfirmForecast = (
  forecastId: number,
  setSnackbar: (snackbar: { type: string; message: string }) => void,
  dispatch: any,
) => {
  verifyProductionPrognose(forecastId).then(() => {
    setSnackbar({
      type: "success",
      message: "Forecast confirmed",
    });
    dispatch(fetchProductionPrognose(forecastId));
  });
};

export const handleUnconfirmForecast = (
  forecastId: number,
  setSnackbar: (snackbar: { type: string; message: string }) => void,
  dispatch: any,
) => {
  unverifyProductionPrognose(forecastId).then(() => {
    setSnackbar({
      type: "info",
      message: "Forecast opened for changes",
    });
    dispatch(fetchProductionPrognose(forecastId));
  });
};

export const handleMouseUp = (
  setIsMouseDown: (isMouseDown: boolean) => void,
) => {
  setIsMouseDown(false);
};

export const handleMouseDown = (
  event: any,
  setIsMouseDown: (isMouseDown: boolean) => void,
) => {
  if (event.button === 0) {
    setIsMouseDown(true);
  }
};

const getDataValuesAndWeights = (data: any, weight: number) => ({
  weight: data?.selected ? weight : 0,
  value: data?.value ?? 0,
});

const getAllDataValuesAndWeights = (
  reading: any,
  forecastWeights: ProductionForecastWeights,
) => {
  const foreca = getDataValuesAndWeights(
    reading.totalProduction.forecaData,
    forecastWeights.forecaWeight,
  );
  const aiolos = getDataValuesAndWeights(
    reading.totalProduction.aiolosData,
    forecastWeights.aiolosWeight,
  );
  const meteologica = getDataValuesAndWeights(
    reading.totalProduction.meteologicaData,
    forecastWeights.meteologicaWeight,
  );
  const adjustmentValue = reading.totalProductionSum.adjustment || 0;

  return {
    forecaDataValue: foreca.value,
    aiolosDataValue: aiolos.value,
    meteologicaDataValue: meteologica.value,
    adjustmentValue,
    forecaWeight: foreca.weight,
    aiolosWeight: aiolos.weight,
    meteologicaWeight: meteologica.weight,
  };
};

const calculateAverageValue = (
  forecaDataValue: number,
  aiolosDataValue: number,
  meteologicaDataValue: number,
  forecaWeight: number,
  aiolosWeight: number,
  meteologicaWeight: number,
  adjustmentValue: number,
  selected: boolean,
): number => {
  const totalWeight = forecaWeight + aiolosWeight + meteologicaWeight;
  const weightedSum =
    forecaDataValue * forecaWeight +
    aiolosDataValue * aiolosWeight +
    meteologicaDataValue * meteologicaWeight;

  const averageValue = totalWeight > 0 ? weightedSum / totalWeight : 0;
  return selected ? averageValue + adjustmentValue : averageValue;
};

export const calculateCapacities = (
  park: ProductionPark,
  forecast: any,
): ProductionPrognoseValues[] => {
  const capacities: ProductionPrognoseValues[] = [];
  const totalProductions = forecast.cellsJson[park.id] || [];
  const forecastWeights =
    forecast.filtersJson[0].forecastWeights[park.priceArea];

  totalProductions.slice(0, 25).forEach((production: any) => {
    const {
      forecaDataValue,
      aiolosDataValue,
      meteologicaDataValue,
      adjustmentValue,
      forecaWeight,
      aiolosWeight,
      meteologicaWeight,
    } = getAllDataValuesAndWeights(production, forecastWeights);

    const averageValue = calculateAverageValue(
      forecaDataValue,
      aiolosDataValue,
      meteologicaDataValue,
      forecaWeight,
      aiolosWeight,
      meteologicaWeight,
      adjustmentValue,
      production.totalProductionSum.forecast.selected,
    );

    const totalProduction: TotalProduction = {
      dateTime: production.datetime,
      forecaWind: production.totalProduction.forecaWind,
      forecaSymbol: production.totalProduction.forecaSymbol,
      forecaData: {
        ...production.totalProduction.forecaData,
        value: Number((forecaDataValue / 1000).toFixed(2)),
      },
      aiolosData: {
        ...production.totalProduction.aiolosData,
        value: Number((aiolosDataValue / 1000).toFixed(2)),
      },
      meteologicaData: {
        ...production.totalProduction.meteologicaData,
        value: Number((meteologicaDataValue / 1000).toFixed(2)),
      },
    };

    const totalProductionSum: TotalProductionSum = {
      forecast: {
        value: averageValue / 1000,
        selected: production.totalProductionSum.forecast.selected,
      },
      adjustment: adjustmentValue,
    };

    capacities.push({
      totalProduction,
      totalProductionSum,
    });
  });

  return capacities;
};

export const calculateSummaryRow = (
  tableMatrix: TableMatrixProduction[],
  setTableMatrix: (matrix: TableMatrixProduction[]) => void,
  selectedPriceArea: number,
  rowIndex: number,
) => {
  const tableMatrixClone = [...tableMatrix];

  const calculateSums = (rowIndex: number) => {
    let totalSum = 0;

    tableMatrix[selectedPriceArea].parks.forEach((parkRow: ParkData) => {
      const forecast = parkRow.readings[rowIndex].totalProductionSum.forecast;
      const weights = tableMatrix[selectedPriceArea].forecastWeights;

      if (forecast.selected) {
        const {
          forecaDataValue,
          aiolosDataValue,
          meteologicaDataValue,
          forecaWeight,
          aiolosWeight,
          meteologicaWeight,
        } = getAllDataValuesAndWeights(parkRow.readings[rowIndex], weights);

        const totalWeight = forecaWeight + aiolosWeight + meteologicaWeight;
        const weightedSum =
          forecaDataValue * forecaWeight +
          aiolosDataValue * aiolosWeight +
          meteologicaDataValue * meteologicaWeight;

        const averageValue = totalWeight > 0 ? weightedSum / totalWeight : 0;

        totalSum += averageValue;
      }
    });

    return totalSum;
  };

  const parkSums = calculateSums(rowIndex);
  const correction =
    tableMatrix[selectedPriceArea].periods[rowIndex].adjustment;

  tableMatrix[selectedPriceArea].periods[rowIndex].total = parkSums;
  tableMatrix[selectedPriceArea].periods[rowIndex].forecast =
    parkSums + correction;

  setTableMatrix(tableMatrixClone);
};

export const calculateTotalValues = (
  tableMatrix: TableMatrixProduction[],
  setTableMatrix: (matrix: TableMatrixProduction[]) => void,
  selectedPriceArea: number,
) => {
  if (tableMatrix.length === 0) {
    return;
  }

  const tableMatrixClone = [...tableMatrix];

  const collectForecastValues = () => {
    const forecastValues: number[] = new Array(25).fill(0);

    tableMatrix[selectedPriceArea].parks.forEach((parkRow: ParkData) => {
      parkRow.readings.forEach((reading, index) => {
        if (reading.totalProductionSum.forecast.selected) {
          forecastValues[index] += reading.totalProductionSum.forecast.value;
        }
      });
    });

    return forecastValues;
  };

  const forecastValues = collectForecastValues();

  forecastValues.forEach((value, index) => {
    const correction = tableMatrix[selectedPriceArea].periods[index].adjustment;

    tableMatrixClone[selectedPriceArea].periods[index].total = value;
    tableMatrixClone[selectedPriceArea].periods[index].forecast =
      value + correction;
  });

  setTableMatrix(tableMatrixClone);
};

export const calculatePeriodData = (
  totalValues: { hour: number; value: number; adjustment: number }[],
): PeriodData[] => {
  return totalValues.slice(0, 25).map((value) => ({
    total: value.value,
    adjustment: value.adjustment,
    forecast: value.value + value.adjustment,
  }));
};

export const handleUpdateProductionPrognosisData = async (
  forecastDate: string,
  forecastType: string,
  setSnackbar: (snackbar: { type: string; message: string }) => void,
  setTableMatrix: (matrix: TableMatrixProduction[]) => void,
  productionParks: ProductionPark[],
  setUpdating: (updating: boolean) => void,
) => {
  setUpdating(true);
  try {
    const updatedForecast = await updateProductionForecastData(
      forecastDate,
      forecastType,
    );

    const updatedTableMatrix = productionParks.map((park) => {
      const forecastWeights =
        updatedForecast.filtersJson[0].forecastWeights[park.priceArea];
      const totalValues =
        updatedForecast.productionPrognoseValues.totalValues[park.priceArea];

      if (!totalValues) {
        throw new Error(
          `totalValues is undefined for price area ${park.priceArea}`,
        );
      }

      if (!forecastWeights) {
        throw new Error(
          `forecastWeights is undefined for price area ${park.priceArea}`,
        );
      }

      const periods: PeriodData[] = totalValues.map((totalValue) => ({
        total: totalValue?.value ?? 0,
        adjustment: totalValue?.adjustment ?? 0,
        forecast: totalValue?.value ?? 0,
      }));

      return {
        priceArea: park.priceArea,
        parks: [
          {
            productionPark: park,
            readings: calculateCapacities(park, updatedForecast),
          },
        ],
        periods: periods,
        forecastWeights: forecastWeights,
      };
    });

    const groupedTableMatrix = updatedTableMatrix.reduce((acc, curr) => {
      const existing = acc.find((item) => item.priceArea === curr.priceArea);
      if (existing) {
        existing.parks.push(...curr.parks);
      } else {
        acc.push(curr);
      }
      return acc;
    }, []);

    setTableMatrix(groupedTableMatrix);
    setSnackbar({
      type: "success",
      message: "Production forecast data updated successfully",
    });
  } catch (error) {
    console.error("Error updating production forecast data:", error);
    setSnackbar({
      type: "error",
      message:
        "Failed to update production forecast data: " +
        (error as Error).message,
    });
  } finally {
    setUpdating(false);
  }
};

export const handlePriceAreaClick = (
  areaIndex: number,
  setSelectedPriceArea: (areaIndex: number) => void,
) => {
  setSelectedPriceArea(areaIndex);
};

export const handleSetCorrection = (
  value: number,
  rowIndex: number,
  tableMatrix: TableMatrixProduction[],
  setTableMatrix: (matrix: TableMatrixProduction[]) => void,
  selectedPriceArea: number,
  calculateTotal: () => void,
) => {
  const tableMatrixClone = [...tableMatrix];
  const period = tableMatrixClone[selectedPriceArea].periods[rowIndex];

  const previousCorrection = period.adjustment;
  const correctionDifference = value - previousCorrection;

  period.adjustment = value;
  period.forecast += correctionDifference;

  setTableMatrix(tableMatrixClone);
  calculateTotal();
};

export const handleSetParkCorrection = (
  value: number,
  parkIndex: number,
  rowIndex: number,
  tableMatrix: TableMatrixProduction[],
  setTableMatrix: (matrix: TableMatrixProduction[]) => void,
  selectedPriceArea: number,
  calculateTotal: () => void,
) => {
  const tableMatrixClone = [...tableMatrix];
  const park = tableMatrixClone[selectedPriceArea].parks[parkIndex];
  const reading = park.readings[rowIndex];

  const previousAdjustment = reading.totalProductionSum.adjustment;
  const adjustmentDifference = value - previousAdjustment;

  reading.totalProductionSum.adjustment = value;
  reading.totalProductionSum.forecast.value += adjustmentDifference;

  setTableMatrix(tableMatrixClone);
  calculateTotal();
};

export const aggregateForecastData = (
  cellsJson: Record<string, PrognosePeriodProduction[]>,
  priceArea: string,
  productionParks: ProductionPark[],
) => {
  const aggregatedData: Record<number, CellJsonPrognosePeriodProduction> = {};

  const filteredParks = productionParks.filter(
    (park) => park.priceArea === priceArea,
  );

  filteredParks.forEach((park) => {
    const parkId = park.id;
    if (cellsJson[parkId]) {
      cellsJson[parkId].forEach(
        (period: CellJsonPrognosePeriodProduction, index: number) => {
          if (!aggregatedData[index]) {
            aggregatedData[index] = {
              datetime: period.datetime,
              totalProduction: {
                forecaData: { value: 0, selected: true },
                aiolosData: { value: 0, selected: true },
                meteologicaData: { value: 0, selected: true },
              },
              totalProductionSum: {
                forecast: { value: 0, selected: true },
                adjustment: 0,
              },
            };
          }

          if (period.totalProduction.forecaData?.selected) {
            aggregatedData[index].totalProduction.forecaData.value +=
              period.totalProduction.forecaData.value;
          }
          if (period.totalProduction.aiolosData?.selected) {
            aggregatedData[index].totalProduction.aiolosData.value +=
              period.totalProduction.aiolosData.value;
          }
          if (period.totalProduction.meteologicaData?.selected) {
            aggregatedData[index].totalProduction.meteologicaData.value +=
              period.totalProduction.meteologicaData.value;
          }
        },
      );
    }
  });

  return aggregatedData;
};

export const handleWeightChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  weightType: keyof ProductionForecastWeights,
  tableMatrix: TableMatrixProduction[],
  setTableMatrix: (matrix: TableMatrixProduction[]) => void,
  globalForecastWeights: Record<string, ProductionForecastWeights>,
  forecast: ProductionForecast,
  setWeightChangeTrigger: (value: (prev: number) => number) => void,
  selectedPriceArea: number,
) => {
  const tableMatrixClone = [...tableMatrix];
  const newWeight = parseFloat(e.target.value) || 0;

  const matrix = tableMatrixClone[selectedPriceArea];
  const priceArea = matrix.priceArea;

  if (!globalForecastWeights[priceArea]) {
    globalForecastWeights[priceArea] = {} as ProductionForecastWeights;
  }

  const otherWeightTypes: (keyof ProductionForecastWeights)[] =
    forecast.filtersJson[0].type === ProductionParkType.SOLAR
      ? ["forecaWeight", "aiolosWeight"]
      : ["aiolosWeight", "meteologicaWeight"];

  const remainingWeight = 100 - newWeight;

  globalForecastWeights[priceArea][weightType] = newWeight;

  otherWeightTypes
    .filter((type) => type !== weightType)
    .forEach((type) => {
      globalForecastWeights[priceArea][type] = remainingWeight;
    });

  matrix.forecastWeights = globalForecastWeights[priceArea];

  const updatedCapacities = matrix.parks.map((park, parkIndex) => {
    const updatedReadings = calculateWeightCapacities(
      park.productionPark,
      {
        ...forecast,
        filtersJson: [
          {
            ...forecast.filtersJson[0],
            forecastWeights: globalForecastWeights[priceArea],
          },
        ],
      },
      tableMatrixClone,
      selectedPriceArea,
      parkIndex,
    );

    updatedReadings.forEach((reading, index) => {
      const originalReading = park.readings[index];
      const allSelectedFalse =
        !originalReading.totalProduction.forecaData?.selected &&
        !originalReading.totalProduction.aiolosData?.selected &&
        !originalReading.totalProduction.meteologicaData?.selected;

      reading.totalProduction = {
        ...reading.totalProduction,
        forecaData: {
          ...reading.totalProduction.forecaData,
          selected: originalReading.totalProduction.forecaData?.selected,
        },
        aiolosData: {
          ...reading.totalProduction.aiolosData,
          selected: originalReading.totalProduction.aiolosData?.selected,
        },
        meteologicaData: {
          ...reading.totalProduction.meteologicaData,
          selected: originalReading.totalProduction.meteologicaData?.selected,
        },
      };

      reading.totalProductionSum = {
        ...reading.totalProductionSum,
        forecast: {
          ...reading.totalProductionSum.forecast,
          value: allSelectedFalse
            ? 0
            : reading.totalProductionSum.forecast.value,
          selected: originalReading.totalProductionSum.forecast.selected,
        },
      };
    });

    return updatedReadings;
  });

  matrix.parks.forEach((park, parkIndex) => {
    park.readings = updatedCapacities[parkIndex];
  });

  setTableMatrix(tableMatrixClone);
  setWeightChangeTrigger((prev: number) => prev + 1);
};

export const calculateWeightCapacities = (
  park: ProductionPark,
  forecast: any,
  tableMatrixClone: TableMatrixProduction[],
  selectedPriceAreaIndex: number,
  parkIndex: number,
): ProductionPrognoseValues[] => {
  const capacities: ProductionPrognoseValues[] = [];
  const totalProductions = forecast.cellsJson[park.id] || [];
  const forecastWeights =
    tableMatrixClone[selectedPriceAreaIndex].forecastWeights;

  totalProductions.slice(0, 25).forEach((production: any, rowIndex: number) => {
    const reading =
      tableMatrixClone[selectedPriceAreaIndex].parks[parkIndex].readings[
        rowIndex
      ];

    const {
      forecaDataValue,
      aiolosDataValue,
      meteologicaDataValue,
      adjustmentValue,
      forecaWeight,
      aiolosWeight,
      meteologicaWeight,
    } = getAllDataValuesAndWeights(production, forecastWeights);

    const totalWeight = forecaWeight + aiolosWeight + meteologicaWeight;
    const averageValue =
      totalWeight === 0
        ? 0
        : calculateAverageValue(
            forecaDataValue,
            aiolosDataValue,
            meteologicaDataValue,
            forecaWeight,
            aiolosWeight,
            meteologicaWeight,
            adjustmentValue,
            reading.totalProductionSum.forecast.selected,
          );

    const totalProduction: TotalProduction = {
      dateTime: production.datetime,
      forecaWind: reading.totalProduction.forecaWind,
      forecaSymbol: reading.totalProduction.forecaSymbol,
      forecaData: reading.totalProduction.forecaData,
      aiolosData: reading.totalProduction.aiolosData,
      meteologicaData: reading.totalProduction.meteologicaData,
    };

    const totalProductionSum: TotalProductionSum = {
      forecast: {
        value: averageValue / 1000,
        selected: reading.totalProductionSum.forecast.selected,
      },
      adjustment: adjustmentValue,
    };

    capacities.push({
      totalProduction,
      totalProductionSum,
    });
  });

  return capacities;
};

export const handleToggleAllPeriods = (
  tableMatrix: TableMatrixProduction[],
  setTableMatrix: (matrix: TableMatrixProduction[]) => void,
  selectedPriceArea: number,
  forecastType: ForecastType | "forecast",
  parkIndex: number,
  overallValues: any[],
  setOverallValues: (values: any) => void,
) => {
  const tableMatrixClone = [...tableMatrix];
  const park = tableMatrixClone[selectedPriceArea].parks[parkIndex];

  const allSelected =
    forecastType === "forecast"
      ? park.readings
          .slice(0, 25)
          .every((reading) => reading.totalProductionSum.forecast.selected)
      : park.readings
          .slice(0, 25)
          .every((reading) => reading.totalProduction[forecastType]?.selected);

  const updatedOverallValues = [...overallValues];

  park.readings.slice(0, 25).forEach((reading, rowIndex) => {
    if (forecastType === "forecast") {
      reading.totalProductionSum.forecast.selected = !allSelected;
      const overallRow =
        updatedOverallValues[selectedPriceArea].periods[rowIndex];
      if (!reading.totalProductionSum.forecast.selected) {
        overallRow.forecast -= reading.totalProductionSum.forecast.value;
      } else {
        overallRow.forecast += reading.totalProductionSum.forecast.value;
      }
    } else if (reading.totalProduction[forecastType]) {
      reading.totalProduction[forecastType].selected = !allSelected;
      const overallRow =
        updatedOverallValues[selectedPriceArea].periods[rowIndex];
      if (!reading.totalProduction[forecastType].selected) {
        overallRow[forecastType] -= reading.totalProduction[forecastType].value;
      } else {
        overallRow[forecastType] += reading.totalProduction[forecastType].value;
      }
    }
  });

  park.readings.slice(0, 25).forEach((reading) => {
    calculateTotalProductionSum(
      reading,
      tableMatrix[selectedPriceArea].forecastWeights,
    );
  });

  setOverallValues(updatedOverallValues);
  setTableMatrix(tableMatrixClone);

  park.readings.slice(0, 25).forEach((_, rowIndex) => {
    calculateSummaryRow(
      tableMatrixClone,
      setTableMatrix,
      selectedPriceArea,
      rowIndex,
    );
  });
};

const calculateTotalProductionSum = (
  reading: any,
  weights: ProductionForecastWeights,
) => {
  let totalSum = 0;
  let totalWeight = 0;

  if (reading.totalProduction.forecaData?.selected) {
    totalSum += reading.totalProduction.forecaData.value * weights.forecaWeight;
    totalWeight += weights.forecaWeight;
  }
  if (reading.totalProduction.aiolosData?.selected) {
    totalSum += reading.totalProduction.aiolosData.value * weights.aiolosWeight;
    totalWeight += weights.aiolosWeight;
  }
  if (reading.totalProduction.meteologicaData?.selected) {
    totalSum +=
      reading.totalProduction.meteologicaData.value * weights.meteologicaWeight;
    totalWeight += weights.meteologicaWeight;
  }

  const averageValue = totalWeight > 0 ? totalSum / totalWeight : 0;
  reading.totalProductionSum.forecast.value =
    averageValue + reading.totalProductionSum.adjustment;
};
