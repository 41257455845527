import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import { UnProtectedRoute } from "./UnProtectedRoute";
import Login from "views/Authentication/Login";
import Register from "views/Authentication/Register";
import Readings from "views/Dashboard/Reports/Readings";
import SavedQueries from "views/Dashboard/Reports/SavedQueries";
import EditSavedQuery from "views/Dashboard/Reports/SavedQueries/EditSavedQuery";
import ManageSamples from "views/Dashboard/Forecasts/ConsumptionForecasts";
import CreateConsumptionForecast from "views/Dashboard/Forecasts/ConsumptionForecasts/ForecastsTable/CreateConsumptionForecast";
import EditSample from "views/Dashboard/Forecasts/ConsumptionForecasts/EditSample";
import SavedSamples from "views/Dashboard/Forecasts/SavedSamples";
import EditSavedSample from "views/Dashboard/Forecasts/SavedSamples/EditSavedSample";
import Clients from "views/Dashboard/Registry/Clients";
import EditClient from "views/Dashboard/Registry/EditClient";
import Contracts from "views/Dashboard/Registry/Contracts";
import MeteringPoints from "views/Dashboard/Registry/MeteringPoints";
import Users from "views/Dashboard/Settings/Users";
import Operators from "views/Dashboard/Settings/Operators";
import ForecaSettings from "views/Dashboard/Settings/ForecaSettings";
import ElevationCoefficents from "views/Dashboard/Settings/ElevationCoefficents";
import ProductionParks from "views/Dashboard/Registry/ProductionParks";
import AddProductionPark from "views/Dashboard/Registry/ProductionParks/AddProductionPark";
import EditProductionPark from "views/Dashboard/Registry/ProductionParks/EditProductionPark/EditProductionPark";
import Litgrid from "views/Dashboard/Settings/Litgrid";
import ReadingsForecasts from "views/Dashboard/Forecasts/ProductionForecasts";
import ProductionForecast from "views/Dashboard/Forecasts/ProductionForecasts/ProductionForecast";
import ClientReports from "views/Dashboard/ClientReports";
import UserProfile from "views/Dashboard/Settings/UserProfile";
import Unauthorized from "../views/Authentication/Unauthorized/Unauthorized";
import SupplyPlans from "views/Dashboard/SupplyPlans";

export enum UnprotectedPaths {
  "Login" = "login",
  "Register" = "register",
  "Unauthorized" = "unauthorized",
}

export enum ProtectedPaths {
  "Readings" = "reports/readings",
  "SavedQueries" = "reports/saved-queries",
  "EditSavedQuery" = "reports/edit-query",
  "ReportsContracts" = "reports/contracts",
  "CreateConsumptionForecast" = "forecasts/consumption/create",
  "EditConsumptionForecast" = "forecasts/consumption/edit",
  "SavedSamples" = "forecasts/saved-samples",
  "EditSavedSample" = "forecasts/saved-samples/edit",
  "Consumption" = "forecasts/consumption",
  "Production" = "forecasts/production",
  "EditProductionForecast" = "forecasts/production/edit",
  "SupplyPlans" = "supply-plans",
  "Clients" = "registry/clients",
  "MeteringPoints" = "registry/metering-points",
  "ClientContracts" = "registry/contracts",
  "ProductionParks" = "registry/production-parks",
  "AddProductionParks" = "registry/add/production-parks",
  "EditProductionParks" = "registry/edit/production-parks",
  "Users" = "settings/users",
  "Operators" = "settings/operators",
  "ForecaSettings" = "settings/foreca-coefficents",
  "ElevationCoefficents" = "settings/elevation-coefficents",
  "AccountSettings" = "account-settings",
  "Litgrid" = "settings/litgrid",
  "UserProfile" = "settings/user-profile",
  "ClientReports" = "dashboard/reports",
}

export default () => {
  const unauthenticatedRoutes = [
    {
      path: "/login",
      element: (
        <UnProtectedRoute>
          <Login />
        </UnProtectedRoute>
      ),
    },
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/unauthorized",
      element: <Unauthorized />,
    },
  ];

  const authenticatedRoutes = [
    {
      path: "/",
      element: <ProtectedRoute />,
      children: [
        {
          path: ProtectedPaths.Readings,
          element: <Readings />,
        },
        {
          path: ProtectedPaths.SavedQueries,
          element: <SavedQueries />,
        },
        {
          path: `/${ProtectedPaths.EditSavedQuery}/:queryId`,
          element: <EditSavedQuery />,
        },
        {
          path: `/${ProtectedPaths.Production}`,
          element: <ReadingsForecasts type="production" />,
        },
        {
          path: `/${ProtectedPaths.EditProductionForecast}/:forecastId`,
          element: <ProductionForecast />,
        },
        {
          path: `/${ProtectedPaths.Consumption}`,
          element: <ManageSamples />,
        },
        {
          path: `/${ProtectedPaths.CreateConsumptionForecast}/:forecastId/:forecastDate`,
          element: <CreateConsumptionForecast />,
        },
        {
          path: `/${ProtectedPaths.EditConsumptionForecast}/:sampleId`,
          element: <EditSample />,
        },
        {
          path: ProtectedPaths.SavedSamples,
          element: <SavedSamples />,
        },
        {
          path: `/${ProtectedPaths.EditSavedSample}/:sampleId?`,
          element: <EditSavedSample />,
        },
        {
          path: ProtectedPaths.Clients,
          element: <Clients />,
        },
        {
          path: `/${ProtectedPaths.Clients}/:clientId`,
          element: <EditClient />,
        },
        {
          path: ProtectedPaths.MeteringPoints,
          element: <MeteringPoints />,
        },
        {
          path: ProtectedPaths.ClientContracts,
          element: <Contracts />,
        },
        {
          path: ProtectedPaths.Users,
          element: <Users />,
        },
        {
          path: `${ProtectedPaths.Users}/:userId`,
          element: <UserProfile />,
        },
        {
          path: ProtectedPaths.Operators,
          element: <Operators />,
        },
        {
          path: ProtectedPaths.ProductionParks,
          element: <ProductionParks />,
        },
        {
          path: ProtectedPaths.AddProductionParks,
          element: <AddProductionPark />,
        },
        {
          path: ProtectedPaths.ForecaSettings,
          element: <ForecaSettings />,
        },
        {
          path: ProtectedPaths.ElevationCoefficents,
          element: <ElevationCoefficents />,
        },
        {
          path: `${ProtectedPaths.EditProductionParks}/:productionParkId`,
          element: <EditProductionPark />,
        },
        {
          path: ProtectedPaths.Litgrid,
          element: <Litgrid />,
        },
        {
          path: ProtectedPaths.ClientReports,
          element: <ClientReports />,
        },
        {
          path: `${ProtectedPaths.SupplyPlans}/:date?`,
          element: <SupplyPlans />,
        },
      ],
    },
  ];

  const router = createBrowserRouter([
    ...unauthenticatedRoutes,
    ...authenticatedRoutes,
  ]);

  return <RouterProvider router={router} />;
};
