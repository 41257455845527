import React, { useEffect, useState } from "react";
import MainLayout from "layouts/Main";
import { Typography, Box, FormControl, Button, Divider } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useAppDispatch, useAppSelector } from "hooks";
import Table from "components/Table";
import dayjs, { Dayjs } from "dayjs";
import { PeriodPresetTypes } from "helpers/enums";
import Select from "components/Select";
import {
  selectCurrentRoleClientId,
  selectReportsQueryOptions,
} from "store/selectors";
import { fetchReportsQueryOptions } from "store/appSlice";
import { grey } from "@mui/material/colors";
import { formatNumberByThousands } from "helpers/numbers";
import { getClientReport } from "api/resources/clients";
import { ClientReport } from "api/models/Clients";

const Reports = () => {
  const reportsQueryOptions = useAppSelector(selectReportsQueryOptions);
  const currentRoleClientId = useAppSelector(selectCurrentRoleClientId);
  const [snackbar, setSnackbar] = useState(null);
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [selectedPeriod, setSelectedPeriod] = useState<string | "">("");
  const [selectedMonth, setSelectedMonth] = useState<Dayjs | null>(null);
  const [clientReport, setClientReport] = useState<ClientReport>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchReportsQueryOptions());
  }, [dispatch]);

  useEffect(() => {
    if (startDate && endDate && currentRoleClientId) {
      setLoading(true);
      getClientReport(
        currentRoleClientId,
        dayjs(startDate).format("YYYY-MM-DD"),
        dayjs(endDate).format("YYYY-MM-DD"),
      )
        .then((result: any) => {
          setClientReport(result.data);
          setLoading(false);
        })
        .catch((error: any) => {
          console.log({ error });
          setLoading(false);
        });
    }
  }, [startDate, endDate, currentRoleClientId]);

  const snackbarClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar(null);
  };

  const handlePeriodChange = (value: any) => {
    const newSelectedPeriod = value.props.value;

    if (newSelectedPeriod) {
      const periodStartDate = reportsQueryOptions.periodPresets.find(
        (preset) => preset.title === value.props.value,
      )?.period.from;

      const periodEndDate = reportsQueryOptions.periodPresets.find(
        (preset) => preset.title === value.props.value,
      )?.period.to;

      setStartDate(dayjs(periodStartDate));
      setEndDate(dayjs(periodEndDate));
      setSelectedPeriod(newSelectedPeriod);
    }
  };

  let tableData = null;

  if (clientReport) {
    tableData = [
      {
        item: "Production",
        value: null,
        color: grey[100],
        bold: true,
      },
      {
        item: "Production quantity (MWh)",
        value: clientReport.production.quantityMWh,
        type: "production",
      },
      {
        item: "Production sales revenue (EUR)",
        value: formatNumberByThousands(clientReport.production.salesRevenueEur),
        type: "production",
      },
      {
        item: "Production weighed average price (EUR/MWh)",
        value: clientReport.production.weightedAveragePriceEurPerMWh,
        type: "production",
      },
      {
        item: "Consumption",
        value: null,
        color: grey[100],
        bold: true,
      },
      {
        item: "Consumption quantity (MWh)",
        value: clientReport.consumption.quantityMWh,
        type: "consumption",
      },
      {
        item: "Consumption purchase cost (EUR)",
        value: clientReport.consumption.purchaseCostEur,
        type: "consumption",
      },
      {
        item: "Consumption weighed average price (EUR/MWh)",
        value: clientReport.consumption.weightedAveragePriceEurPerMWh,
        type: "consumption",
      },
      {
        item: "Production",
        value: null,
        color: grey[100],
        bold: true,
      },
      {
        item: "Metered supply of production portfolio (MWh)",
        value: clientReport.meteredSupply.quantityMWh,
      },
      {
        item: "Metered supply of production portfolio (EUR)",
        value: clientReport.meteredSupply.revenueEur,
      },
      {
        item: "Production forecast (MWh)",
        value: clientReport.productionForecast.forecastMWh,
      },
      {
        item: "Forecasted production sales revenue (EUR)",
        value: clientReport.productionForecast.forecastSalesRevenueEur,
      },
      {
        item: "Forecasted production average sales revenue (EUR/MWh)",
        value: clientReport.productionForecast.averageSalesPriceEurPerMWh,
      },
      {
        item: "Balance energy",
        value: null,
        color: grey[100],
        bold: true,
      },
      {
        item: "Balance energy purchased quantity (MWh)",
        value: clientReport.balanceEnergy.purchasedQuantityMWh,
        type: "balance",
      },
      {
        item: "Balance energy total purchase cost (EUR)",
        value: clientReport.balanceEnergy.totalPurchaseCostEur,
        type: "balance",
      },
      {
        item: "Balance energy purchase cost (EUR/MWh)",
        value: clientReport.balanceEnergy.purchasePriceEurPerMWh,
        type: "balance",
      },
      {
        item: "Balance enegy sold quantity (MWh)",
        value: clientReport.balanceEnergy.soldQuantityMWh,
        type: "balance",
      },
      {
        item: "Balance energy total sales revenue (EUR)",
        value: clientReport.balanceEnergy.totalSalesRevenueEur,
        type: "balance",
      },
      {
        item: "Balance energy sales price (EUR/MWh)",
        value: clientReport.balanceEnergy.salesPriceEurPerMWh,
        type: "balance",
      },
      {
        item: "Average price (inc. balance energy) (EUR/MWh)",
        value: clientReport.prices.averagePriceEurPerMWh,
        type: "balance",
      },
      {
        item: "Weighed average price (EUR/MWh)",
        value: clientReport.prices.weightedAveragePriceEurPerMWh,
        type: "balance",
      },
      {
        item: "Balance energy cost (EUR/MWh)",
        value: clientReport.prices.balanceEnergyCostEurPerMWh,
        type: "balance",
      },
      {
        item: "Forecast error (%)",
        value: clientReport.forecastErrorPercentage,
        type: "forecast",
        color: grey[100],
        bold: true,
      },
    ];
  }

  const columns = [
    {
      accessorKey: "item",
      header:
        dayjs(startDate).format("DD.MM.YYYY") +
        " - " +
        dayjs(endDate).format("DD.MM.YYYY"),
    },
    {
      accessorKey: "value",
      header: "",
      meta: { isNumeric: true },
    },
  ];

  return (
    <MainLayout
      snackbar={{
        open: snackbar,
        handleClose: snackbarClose,
        type: snackbar?.type,
        message: snackbar?.message,
      }}
    >
      <Typography variant="h5">Reports</Typography>
      <Box maxWidth="md">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginTop: 4, marginBottom: 2 }}
        >
          <Box>
            <Typography component="p" sx={{ mb: 2 }}>
              Summarized report
            </Typography>
            <FormControl>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Select
                  label="Period"
                  id="period"
                  name="period"
                  minWidth={180}
                  onChange={(event: any, value: any) =>
                    handlePeriodChange(value)
                  }
                  value={selectedPeriod}
                  options={
                    reportsQueryOptions
                      ? [...reportsQueryOptions.periodPresets].map(
                          (periodPreset) => {
                            return {
                              label:
                                PeriodPresetTypes[
                                  periodPreset.title as keyof typeof PeriodPresetTypes
                                ],
                              value: periodPreset.title,
                            };
                          },
                        )
                      : []
                  }
                />
                <DatePicker
                  sx={{ ml: 1, mr: 1, maxWidth: "180px" }}
                  slotProps={{ textField: { size: "small" } }}
                  name="startDate"
                  value={startDate}
                  disableFuture={true}
                  onChange={(value) => {
                    setStartDate(dayjs(value));
                  }}
                  label="Start date"
                />
                <DatePicker
                  sx={{ mr: 1, maxWidth: "180px" }}
                  slotProps={{ textField: { size: "small" } }}
                  name="endDate"
                  value={endDate}
                  disableFuture={true}
                  minDate={startDate}
                  onChange={(value) => {
                    setEndDate(dayjs(value));
                  }}
                  label="End date"
                />
              </Box>
            </FormControl>
          </Box>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ margin: "0 1.6rem" }}
          />
          <Box>
            <Typography component="p" sx={{ mb: 2 }}>
              Export detailed report
            </Typography>
            <FormControl>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                <DatePicker
                  sx={{ mr: 1, maxWidth: "180px" }}
                  slotProps={{ textField: { size: "small" } }}
                  name="period"
                  value={selectedMonth}
                  disableFuture={true}
                  minDate={dayjs().subtract(2, "year")}
                  openTo="month"
                  views={["year", "month"]}
                  onChange={(value) => {
                    setSelectedMonth(dayjs(value));
                  }}
                  label="Period"
                />
                <Button
                  variant="contained"
                  disabled={!selectedMonth}
                  sx={{ ml: 1 }}
                >
                  Export
                </Button>
              </Box>
            </FormControl>
          </Box>
        </Box>
        {tableData && (
          <Table
            isFetching={loading}
            data={tableData}
            columns={columns}
            pageCount={1}
          />
        )}
      </Box>
    </MainLayout>
  );
};

export default Reports;
