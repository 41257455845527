import type { RootState } from "..";

export const selectClients = (state: RootState) => state.app.clients;

export const selectClient = (state: RootState) => state.app.client;

export const selectClientMeteringPoints = (state: RootState) =>
  state.app.clientMeteringPoints;

export const selectUsers = (state: RootState) => state.app.users;

export const selectUser = (state: RootState) => state.app.user;

export const selectOperators = (state: RootState) => state.app.operators;

export const selectMeteringPoints = (state: RootState) =>
  state.app.meteringPoints;

export const selectContracts = (state: RootState) => state.app.contracts;

export const selectReportsQueryOptions = (state: RootState) =>
  state.app.reportsQueryOptions;

export const selectReportsQueries = (state: RootState) =>
  state.app.reportsQueries;

export const selectForecastTemplates = (state: RootState) =>
  state.app.forecastTemplates;

export const selectForecasts = (state: RootState) => state.app.forecasts;

export const selectForecast = (state: RootState) => state.app.forecast;

export const selectProductionParks = (state: RootState) =>
  state.app.productionParks;

export const selectProductionPark = (state: RootState) =>
  state.app.productionPark;

export const selectProductionParkComponents = (state: RootState) =>
  state.app.productionParkComponents;

export const selectProductionParkComponent = (state: RootState) =>
  state.app.productionParkComponent;

export const selectProductionForecasts = (state: RootState) =>
  state.app.productionForecasts;
export const selectProductionForecast = (state: RootState) =>
  state.app.productionForecast;
export const selectProductionForecastSelect = (state: RootState) =>
  state.app.productionForecastSelect;

export const selectForecaCoefficents = (state: RootState) =>
  state.app.forecaCoefficients;

export const selectMe = (state: RootState) => state.app.me;

export const selectCurrentRoleClientId = (state: RootState) =>
  state.app.currentRoleClientId;

export const selectInvites = (state: RootState) => state.app.invites;

export const selectSupplyPlanForecast = (state: RootState) =>
  state.app.supplyPlanForecast;

export const selectSupplyPlanPriceLimits = (state: RootState) =>
  state.app.supplyPlanPriceLimits;

export const selectLitgridLogs = (state: RootState) => state.app.litgridLogs;
